.container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow-y: auto;
  padding: 0;
  box-sizing: border-box;
}

.mobile-greeting {
  display: none;
}

.optionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.optionCard {
  margin: 20px;
  flex: 1;
}

.cardImage {
  margin-top: 20px;
  width: 100%;
  height: auto;
}

.button {
  justify-content: flex-end;
}

.hod-home-options-container {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .optionsContainer {
    flex-direction: column;
    align-items: center;
  }

  .placement-coordinator-options-container {
    margin-top: 1100px;
  }

  .academic-options-container {
    margin-top: 650px;
  }

  .fa-home-options-container {
    margin-top: 1100px;
  }

  .hod-home-options-container {
    width: 90%;
    margin-top: 200px;
  }

  .pc-options-container {
    margin-top: 200px;
  }

  .admin-home-options-container {
    margin-top: 1450px;
  }

  .optionCard {
    width: 90%;
    margin: 20px 0;
  }

  .mobile-greeting {
    display: block;
  }
}
