.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: white;
  background-color: #1565c0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

ul {
  list-style-type: none;
  padding: 0;
  color: white;
  margin: 0;
}

li {
  display: inline-block;
  margin-right: 20px;
}

button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.logout {
  padding: 5px 10px;
  color: red;
  background-color: white;
  border-radius: 20px;
  font-weight: 600;
}

.menu-icon {
  display: none;
}

@media (max-width: 768px) {
  .navbar ul {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    display: none;
  }

  ul.open {
    display: flex;
  }

  li {
    margin: 10px 0;
  }

  .menu-icon {
    display: inline-block;
    margin-left: auto;
  }
}
