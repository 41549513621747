/* Signup.css */
body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

.logo {
  text-align: center;
}

.form-container {
  width: 400px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff; /* Set the background color for the form */
}

.form-heading {
  margin: 0;
  color: #337ab7;
  font-size: 1.25rem;
}

.form-control {
  margin-bottom: 16px;
}

.form-control .MuiFormControl-root {
  margin-top: 16px;
}

.MuiInputLabel-root {
  margin-top: -4px;
}

.button-container {
  text-align: center;
  margin-top: 16px;
}

/* date picker */

.date-picker {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
}

.date-picker:focus {
  outline: none;
  border-color: #337ab7;
  box-shadow: 0 0 0 2px rgba(51, 122, 183, 0.2);
}

.date-picker:hover {
  border-color: #337ab7;
}

.react-datepicker__input-container input {
  border: none;
  outline: none;
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  width: 100%;
}

/* responsive */

@media screen and (max-width: 768px) {
  .signup-container {
    width: 380px;
  }
}
